<template>
<!-- HISTORY: no-version
   V230221.1: Added Actions item to the menu.
   V221107.1: Added Settings item to the menu.
   03/31/22(B0.3): Added Remote Accesses item to the menu.
   03/17/22(B0.2): Built menu items based on the user permissions (policies) + Synched icons and routes w/Aref's.
   10/14/20(B0.1): File was created.
-->
   <nav>
      <v-navigation-drawer permanent
         class="lighten-2 full-height px-0"
         style="background-color: #f6f6f6; height: 91vh; position:sticky; top:50px; z-index:2"
         mini-variant-width="60"
         width="220"
         :mini-variant="miniVariant"
      >
         <v-list class="py-0">
            <v-divider color="white"></v-divider>
            <v-list-item :dense="true">
               <v-list-item-icon>
                  <v-icon class="grey--text">list</v-icon>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title class="body-2 grey--text font-italic">List Service</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
         </v-list>

         <v-divider calss="my-2" dark></v-divider>

         <v-list class="py-0" flat>
            <!-- <v-divider color="white"></v-divider> -->
            <v-list-item v-for="item in permittedItems"
               link
               :key="item.title"
               :to="getLink(item.route)"
            >
               <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title class="caption font-weight-bold" >{{ item.title }}</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
            <v-divider color="white"></v-divider>
            <v-list-item @click="toggleMenu()">
               <v-list-item-icon>
                  <v-icon>more_horiz</v-icon>
               </v-list-item-icon>
            </v-list-item>
         </v-list>
      </v-navigation-drawer>
   </nav>
</template>

<script>
// import { IamSettings } from '../models/iam-settings.js';

export default {
   props: {
      baseRoute: {
         type: String,
         required: true
      },
      debug: {
         type: Boolean,
         default: false
      },
      miniVariant: {
         type: Boolean,
         default: false
      }
   },

   data() {
      return {
         items: [
            { icon: 'dashboard', title: 'Dashboards', route: 'dashboards', policy: 'contact-dashboards-list' },
            // { icon: 'dashboard', title: 'CsDashboardCopy', route: 'dashboards2', policy: 'contact-dashboards-list' },
            // { icon: 'dashboard', title: 'CsDashboardDesigner', route: 'dashboards3', policy: 'contact-dashboards-list' },
            // { icon: 'dashboard', title: 'CsDashDesigner', route: 'dashboards4', policy: 'contact-dashboards-list' },
            // { icon: 'dashboard', title: 'CsDashboards_BehzadVersion', route: 'dashboards5', policy: 'contact-dashboards-list' },
            { icon: 'file_download', title: 'Imports', route: 'imports', policy: 'contact-import-list' },
            { icon: 'mdi-vector-polyline', title: 'Triggers', route: 'triggers', policy: 'contact-trigger-list' },
            { icon: 'file_upload', title: 'Exports', route: 'exports', policy: 'contact-export-list' },
            { icon: 'connect_without_contact', title: 'Actions', route: 'actions', policy: 'contact-action-list-t' },
            { icon: 'delete', title: 'Deletes', route: 'deletes', policy: 'contact-deletes-list' },
            { icon: 'sort', title: 'Indexes', route: 'indexes', policy: 'contact-indexes-list' },
            { icon: 'vpn_key', title: 'Remote Accesses', route: 'access', policy: 'contact-access-list' },
            { icon: 'search', title: 'Search & Analyze', route: 'documents', policy: 'contact-offers-list' },
            { icon: 'settings', title: 'Settings', route: 'settings', policy: 'contact-settings-view' }
            // { icon: 'settings', title: 'Configuration', route: 'config', policy: 'contact-config-list' }
         ],
         permittedItems: [],
         // settings: new IamSettings(this.debug),
         myStyle: 'background-color: #f6f6f6; height: 91vh; overflow-y:unset; position:sticky; top:0'
      }
   },

   computed: {
      userPolicies() {
         // alert('in computed.userPolicies(): val=' + JSON.stringify(this.$store.getters.user.policies));
         return this.$store.getters.user.policies;
      }
   },

   watch: { 
      userPolicies: {
         immediate: true,
         deep: true,
         handler (val) {
            // alert('in watch.userPolicies(): val=' + JSON.stringify(val));
            this.permittedItems = this.items.filter(item => val.includes(item.policy));
            if (this.$store.getters.user.isPowerAdmin) {
               this.permittedItems.push(this.items.find(item => item.route === 'actions'));
               // this.permittedItems.push(this.items.find(item => item.route === 'triggers'));
            }
         }
      }
   },

   methods: {
      getLink(route) {
         return "/" + this.baseRoute + "/" + route;
      },

      toggleMenu() {
         this.$emit('more-click');
      },

      // onScroll(e) {
      //    this.myStyle = 'background-color: #f6f6f6; height: 91vh; overflow-y:unset; position:sticky; top:' + e.target.scrollTop + 'px';
      //    alert(this.myStyle);
      // }
   },
   
   created() { }
}
</script>

<style scope>
   .v-list .v-list-item--active {
      color:#ff6633;
      background: #f6f6f6;
  }
</style>