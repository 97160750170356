/*jshint esversion: 8*/
/* Common functions to be used in different components.
 * HISTORY: no-version
 * V230202.1: Added event_path and event_element fields to BEH_FIELDS + Replaced hasOwnProperty() with hasOwn().
 * V221101.1: Added generateUUID() method.
 * V221027.1: Added hasOwn() method.
 * V221006.1: Modified convertToISODate() by adding setSeconds param (to be used for adding events).
 * V221004.1: Modified convertToISODate() by removing -1 from month.
 * V221003.1: Added isDate(), isNumber(), isBoolean(), convertToBoolean(), and convertToISODate() methods.
 * V220922.1: Modified adjustFilter() to fix a bug that set importId with $nin (fixed it on Aref side).
 * 09/18/22(B0.15): Modified adjustFilterWithImportId() to bypass importId assignment if it has already a value.
 * 09/16/22(B0.14): Modified adjustFilter() and adjustFilterWithImportId() to set the filter's importId with $in.
 * 07/07/22(B0.13): Modified adjustFilter() to support click event for calculated tables with predefined filter.
 * 07/01/22(B0.12): Modified adjustFilter() and adjustFilter_adjust$project() to handle event_date's click event.
 * 06/28/22(B0.11): Added adjustFilter_adjust$project() to inject datePicker output into $project.
 * 06/21/22(B0.10): Modified adjustFilter() to always add importId if doesn't exist +
 *    Added adjustFilterWithImportId() to add importId to $match.
 * 06/17/22(B0.9): Modified adjustFilter() to always replacing importId value.
 * 06/14/22(B0.8): Added device and platform to BEH_FIELDS and adjustFilter().
 * 05/24/22(B0.7): Added BEH_FIELDS and getAllBehavioralFields().
 * 09/24/21(B0.6): In adjustFilter(), changed originalFilter to currentFilter and added originalFilter param +
 *    Added adjustFiletr_matchKeyExists() and adjustFiletr_eventKeyExists functions.
 * 09/21/21(B0.5): In adjustFilter(), added $addFields only if didn't exist.
 * 09/17/21(B0.4): In adjustFilter(), made event_date's values to be in ISO format.
 * 09/13/21(B0.3): Added adjustFilter() and adjustFilter_isEmpty().
 * 04/19/21(B0.2): Added isValidDate().
 * 11/24/20(B0.1): Creation date.
 */

// export default {
//    methods: {
//       clicked(value) {
//          alert(value);
//       }
//    }
//  };

const BEH_FIELDS = [
   { text: 'App Code', value: 'app_code', type: 'string', isEventField: true },
   { text: 'Browser', value: 'browser', type: 'string', isEventField: true },
   { text: 'Device', value: 'device', type: 'string', isEventField: true },
   { text: 'Event Code', value: 'event_code', type: 'string', isEventField: true },
   { text: 'Event Date', value: 'event_date', type: 'date', isEventField: true },
   { text: 'IP', value: 'ip', type: 'string', isEventField: true },
   // { text: 'Is Trigger', value: 'is_trigger', type: 'boolean', isEventField: true },
   { text: 'Operating System', value: 'os', type: 'string', isEventField: true },
   { text: 'Platform', value: 'platform', type: 'string', isEventField: true },
   // { text: 'Process Definition ID', value: 'pdid', type: 'string', isEventField: true }
   { text: 'Event Path', value: 'event_path', type: 'string', isEventField: true },
   { text: 'Event Element', value: 'event_element', type: 'string', isEventField: true }
];
 
// This function is used inside adjustFilter().
function adjustFilter_isEmpty(val) {
   if ((typeof val === 'string' && val === '') || (Array.isArray(val) && val.length === 0))
      return true;
   else
      return false;
}

function adjustFiletr_matchKeyExists(filter, key) {
   // alert('in adjustFiletr_matchKeyExists(): filter=' + JSON.stringify(filter) + '\n\tkey=' + key);
   const match = filter.standard.find(f => f.$match).$match;
   // const result = match.hasOwnProperty(key);
   const result = hasOwn(match, key);
   // alert('in adjustFiletr_matchKeyExists(): result=' + result);
   return result;
}

function adjustFiletr_eventKeyExists(filter, key) {
   // alert('in adjustFiletr_eventKeyExists(): filter=' + JSON.stringify(filter) + '\n\tkey=' + key);
   const eventsObj = filter.standard.find(f => f.$events);
   // const result = eventsObj ? eventsObj.events.$elemMatch.hasOwnProperty(key) : false;
   const result = eventsObj ? hasOwn(eventsObj.events.$elemMatch, key) : false;
   // alert('in adjustFiletr_eventKeyExists(): result=' + result);
   return result;
}

//stdFilters, behFilters
function adjustFilter_adjust$project(filter, dateFilter) {
   // alert(`in adjustFilter_adjust$project(): filter=${JSON.stringify(filter)}\n\ndateFilter=${JSON.stringify(dateFilter)}`);
   // alert(`in adjustFilter_adjust$project(): dateFilter=${JSON.stringify(dateFilter)}`);
   const $projectObj = filter.standard.find(f => f.$project);
   if ($projectObj) {
      let dateWasUpdated = false;
      if (!$projectObj.$project.events || $projectObj.$project.events === 1) {
         $projectObj.$project.events = {
            $filter: {
               input: '$events',
               as: 'event',
               cond: { $and: [] }
            }
         };
      }

      // const $and = $projectObj.$project.events.$filter.cond.$and;
      // $and.forEach(and => {
      //    const key = Object.keys(and)[0];
      //    // alert('and='+JSON.stringify(and) + '\nkey='+key + 'and[key][0]=' + JSON.stringify(and[key][0]));
      //    if ((key === '$gte' || key === '$lte') && and[key][0] === '$$event.event_date') {
      //       // alert('inside if: key='+key);
      //       and[key][1] = dateFilter[key];
      //       dateWasUpdated = true;
      //    } else if (key === '$eq') {
      //       const eqPart1 = and.$eq[0];
      //       // alert('eqPart1='+JSON.stringify(eqPart1));
      //       if (typeof eqPart1 === 'object') {
      //          const eqKey = Object.keys(eqPart1)[0];
      //          // alert('eqKey='+JSON.stringify(eqKey));
      //          if (eqPart1[eqKey] === '$$event.event_date') {
      //             and.$eq[1] = dateFilter.$expr.$eq[1];
      //             dateWasUpdated = true;
      //          }
      //       }
      //    }
      // });


      const allAnds = $projectObj.$project.events.$filter.cond.$and;
      // alert('allAnds=' + JSON.stringify(allAnds));
      const dateFilterKeys = Object.keys(dateFilter);
      // alert('dateFilterKeys.length='+dateFilterKeys.length);
      if (dateFilterKeys.length === 1) {
         const $and = allAnds.filter(and => Object.keys(and)[0] === '$eq');
         // alert('$and for click:'+JSON.stringify($and));
         for (let i = 0; i < $and.length; i++) {
            const and = $and[i];
            // const key = Object.keys(and)[0];
            // alert('origin and='+JSON.stringify(and) + '\nkey='+key + ', and[key][0]=' + JSON.stringify(and[key][0]));
            const eqPart1 = and.$eq[0];
            // alert('eqPart1='+JSON.stringify(eqPart1) + '\n'+typeof eqPart1);
            if (typeof eqPart1 === 'object') {
               const eqKey = Object.keys(eqPart1)[0];
               // alert('eqKey='+JSON.stringify(eqKey));
               if (eqPart1[eqKey] === '$$event.event_date') {
                  and.$eq[1] = dateFilter.$expr.$eq[1];
                  dateWasUpdated = true;
                  break;
               }
            }            
         }

         if (!dateWasUpdated) {
            // alert('before push: dateFilter=' + JSON.stringify(dateFilter));
            allAnds.push(dateFilter.$expr);
         }   
      } else {
         const $and = allAnds.filter(and => ['$gte','$lte'].includes(Object.keys(and)[0]));
         // alert('$and for date:'+ JSON.stringify($and));
         $and.forEach(and => {
            const key = Object.keys(and)[0];
            // alert('origin and='+JSON.stringify(and) + '\nkey='+key + ', and[key][0]=' + JSON.stringify(and[key][0]));
            if (and[key][0] === '$$event.event_date') {
               // alert('inside if: key='+key);
               and[key][1] = dateFilter[key];
               dateWasUpdated = true;
               // alert('new and=' + JSON.stringify(and) + '\ndateFilter='+JSON.stringify(dateFilter));
            }
         });
   
         if (!dateWasUpdated) {
            dateFilterKeys.forEach(key => {
               // alert('adding... key='+key);
               const item = {};
               item[key] = [ '$$event.event_date', dateFilter[key] ];
               // alert('item='+JSON.stringify(item));
               allAnds.push(item);
            });
         }
      }
   }
   // alert(`in adjustFilter_adjust$project(): filter=${JSON.stringify(filter)}`);
}

export function test(value) {
   alert(value);
}

//usage: await sleep(2000);
export function sleep(ms) {
   // alert('ms=' + ms);
   return new Promise(resolve => setTimeout(resolve, ms));
}

//to generate an integer random number between min and max.
export function getRandomInt(min, max) {
   return min + Math.floor(Math.random() * (max - min));
}


/*
 * To check if an item exists in an array or not.
 * src: array of strings to be searched in.
 * item: a string to be searched for.
 * returns true or false.
 */
export function isIncluded(src, item, isCaseSensitive) {
   // alert('src=' + JSON.stringify(src) + '\nitem=' + item + '\nisCaseSensitive=' + isCaseSensitive);
   if (isCaseSensitive)
      return src.filter(s => s.includes(item)).length > 0;
   else
      return src.filter(s => s.toLowerCase().includes(item.toLowerCase())).length > 0;
}

/*
 * https://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript
 * To validate a date string
 * date: a date string in the form of 'mm/dd/yyyy'
 * returns true or false
 */
export function isValidDate(date) {
   // date should be in "mm/dd/yyyy" format
   if ( ! /^\d\d\/\d\d\/\d\d\d\d$/.test(date) )
       return false;

      //  const parts = date.split('/').map((p) => parseInt(p, 10));
      //  parts[0] -= 1;
      //  const newDate = new Date(parts[2], parts[0], parts[1]);
      //  return newDate.getMonth() === parts[0] && newDate.getDate() === parts[1] && newDate.getFullYear() === parts[2];

   let [mm, dd, yyyy] = date.split('/').map((p) => parseInt(p, 10));
   mm -= 1;
   const newDate = new Date(yyyy, mm, dd);
   return newDate.getMonth() === mm && newDate.getDate() === dd && newDate.getFullYear() === yyyy;
}

/*
 * Business Logics:
 * - No changes should affect itself and DatePicker.
 * - date-picker-change should update all other filters.
 * Note: What happens when a dropdown is already selected but this item is not available when date is changed?
 * Note: What happens to the originalFilter if date is changed again. 
 * - dropdown-change shouldn't affect KPIs.
 * - chart-click only affects other charts and tables.
 * Note: What happens if another chart is clicked.
 */
export function adjustFilter(originalFilter, currentFilter, adjustingFilter, chartType, importId) {


   // originalFilter = {"standard":[{"$match":{"importId":{"$in":["6318cc0eb6c8e3a7005d8e62","6318b9c5865f1f17a7576e02","62f2eb2664ce543d7ede3ab1","62f2e93764ce54ffa9de3aad","62f2e2ac64ce548f5ade3aaa","62f2dfa61a1fe2622085528a"]}}}],"columns":["firstname","lastname","address1","address2","city","st","zip"]};
   // currentFilter = {"standard":[{"$match":{"importId":{"$in":["6"]},"events":{"$elemMatch":{"event_code":{"$in":["30500"]}}}}}],"columns":["firstname","lastname","address1","address2","city","st","zip"]};
   // adjustingFilter = {
   //    "importId":["62f2e2ac64ce548f5ade3aaa"],
   //    "event_code":"30500"
   // };
   // // adjustingFilter = {
   // //    "importId":{"$in": ["62f2e2ac64ce548f5ade3aaa"]},
   // //    "event_code":"30500"
   // // };
   // importId = "62f2e2ac64ce548f5ade3aaa";


   // alert('in bt-mixin.adjustFilter(): originalFilter=' + JSON.stringify(originalFilter) +
   //    '\n\tcurrentFilter=' + JSON.stringify(currentFilter) +
   //    '\n\tadjustingFilter=' + JSON.stringify(adjustingFilter) +
   //    '\n\tchartType=' + chartType + 
   //    '\n\timportId=' + importId);
   // console.log('in bt-mixin.adjustFilter(): ' +
   // '\n\tadjustingFilter=' + JSON.stringify(adjustingFilter) +
   // '\n\tchartType=' + chartType);
   const filter = JSON.parse(JSON.stringify(currentFilter));
   let partialFilter;
   if (Array.isArray(adjustingFilter) || typeof adjustingFilter === 'string')
      partialFilter = { event_date: adjustingFilter };
   else
      partialFilter = adjustingFilter;

   // alert('partialFilter=' + JSON.stringify(partialFilter));
   const newEvents = {};
   const $match = filter.standard.find(f => f.$match).$match;
   Object.keys(partialFilter).filter(k => !['$addFields','$dropdownSwitch'].includes(k.toString())).forEach(key => {
      // alert('in bt-mixin.adjustFilter(): key=' + key);
      const val = partialFilter[key];
      switch (key) {
         case 'app_code':
         case 'event_code':
            if (!adjustFiletr_eventKeyExists(originalFilter, key)) {
               if (adjustFilter_isEmpty(val))
                  delete newEvents[key];
               else
                  newEvents[key] = { '$in': Array.isArray(val) ? val : [val] };
            }            
            break;
         case 'event_date':
            if (!adjustFiletr_eventKeyExists(originalFilter, key)) {
               if (adjustFilter_isEmpty(val))
                  delete newEvents[key];
               else {
                  let val1, val2;
                  if (Array.isArray(val)) {
                     val1 = val[0];
                     val2 = val[1];
                  } else {
                     val1 = val;
                     val2 = val;
                  }
                  val1 += 'T00:00:00.000Z';
                  val2 += 'T23:59:59.999Z';
                  newEvents[key] = { '$gte': val1, '$lte': val2 };
                  // alert(`newEvents[${key}]=${JSON.stringify(newEvents[key])}`);

                  // alert('going to adjustFilter_adjust$project for event_date for ' + chartType);
                  adjustFilter_adjust$project(filter, newEvents[key]);
                  // alert('after adjusting $project: chartType=' + chartType + '\nfilter(event_date)=' + JSON.stringify(filter));
               }
            }
            break;
         case 'browser':
         case 'device':
         case 'is_trigger':
         case 'os':
         case 'platform':
            if (!adjustFiletr_eventKeyExists(originalFilter, key)) {
               if (adjustFilter_isEmpty(val))
                  delete newEvents[key];
               else
                  newEvents[key] = Array.isArray(val) ? { '$in': val } : val;
            }
            break;
         case '$expr':
            // alert('going to adjustFilter_adjust$project for $expr for ' + chartType);
            adjustFilter_adjust$project(filter, { $expr: val });
            // alert('after adjusting $project: chartType=' + chartType + '\nfilter(expr)=' + JSON.stringify(filter));
            break;
         default:
            // alert('originalFilter=' + JSON.stringify(originalFilter) + '\nkey=' + key + '\nval=' + JSON.stringify(val));
            if (adjustFiletr_matchKeyExists(originalFilter, key)) {
               // alert('matchKey exists: val=' + JSON.stringify(val));
               if (key === 'importId')
                  $match[key] = { '$in': [val[0]] };  //val[0];
            } else {
               if (adjustFilter_isEmpty(val)) {
                  delete $match[key];
               } else {
                  if (key === 'predefined') {
                     const predefinedKey = Object.keys(val)[0];
                     $match[predefinedKey] = val[predefinedKey];
                  // } else if (key === 'importId' || !partialFilter.hasOwnProperty('$dropdownSwitch') || partialFilter.$dropdownSwitch) {   //V220922.1
                  } else if (key === 'importId' || !hasOwn(partialFilter, '$dropdownSwitch') || partialFilter.$dropdownSwitch) {   //V230201.1
                     $match[key] = Array.isArray(val) ? { '$in': val } : val;
                  } else {
                     $match[key] = Array.isArray(val) ? { '$nin': val } : { '$ne': val };
                  }
               }
            }
            break;
      }
   });

   if (Object.keys(newEvents).length) {
      const eventsObj = filter.standard.find(f => f.$events);
      let events = eventsObj ? eventsObj.events : { $elemMatch: {} };

      Object.keys(newEvents).forEach(key => {
         events.$elemMatch[key] = newEvents[key];
      });

      $match.events = events;
   }

   // if (partialFilter.hasOwnProperty('$addFields') && !filter.standard[0].hasOwnProperty('$addFields'))
   if (hasOwn(partialFilter, '$addFields') && !hasOwn(filter.standard[0], '$addFields'))
      filter.standard.unshift({ $addFields: partialFilter.$addFields });

   // if (partialFilter.hasOwnProperty('$expr')) {
   //    // if ($match.events) {
   //    //    $match.events = {
   //    //       $elemMatch: {}
   //    //    };
   //    // }

   //    // $match.events.$elemMatch.event_date = partialFilter;

   //    // const eqPart1 = partialFilter.$expr.$eq[0];
   //    // const eqKey = Object.keys(eqPart1)[0];
   //    // eqPart1[eqKey] = eqPart1[eqKey].replace('$', '$$event.');
   //    // alert('new partialFilter=' + JSON.stringify(partialFilter));
   //    adjustFilter_adjust$project(filter, partialFilter);
   // }

   // if (chartType != 'Dropdown' && !$match.importId) {
   if (!['Dropdown', 'DatePicker'].includes(chartType) && !$match.importId) {
      if (!originalFilter.standard.find(f => f.$group) || !originalFilter.standard.find(f => f.$group).$group._id.Import)
         $match.importId = importId;
   }
      
   // alert('in bt-mixin.adjustFilter(): newFilter=' + JSON.stringify(filter));
   return filter;
}

export function adjustFilterWithImportId(filterIn, importId) {
   // alert('in bt-mixin.adjustFilterWithImportId(): filterIn=' + JSON.stringify(filterIn) +
   //    '\n\timportId=' + JSON.stringify(importId));
   const filterOut = JSON.parse(JSON.stringify(filterIn));
   const $match = filterOut.standard.find(f => f.$match).$match;
   if (!$match.importId)
      $match.importId = { '$in': [importId] };  //importId;
      
   // console.log('in bt-mixin.adjustFilterWithImportId(): filterOut=' + JSON.stringify(filterOut));
   return filterOut;
}

export function getAllBehavioralFields() {
   return BEH_FIELDS;
}

export function isDate(dateString) {
   if (/^\d/.test(dateString.trim())) {
      let date = Date.parse(dateString.trim());

      if (date < 0 || isNaN(date))
         return false;
      else
         return true;
   }

   return false;
}

export function isNumber(strVal) {
   return !isNaN(strVal.trim());
}

export function isBoolean(strVal) {
   const newVal = strVal.trim().toLowerCase();
   return newVal === 'true' || newVal === 'false';
}

export function convertToBoolean(strVal) {
   const newVal = strVal.trim().toLowerCase();
   return newVal === 'true';
}

export function convertToISODate(date, setSeconds) {
   // const utcDate = Date.UTC(
   //    date.getUTCFullYear(),
   //    date.getUTCMonth(),// - 1, V221004.1
   //    date.getUTCDate(),
   //    date.getUTCHours(),
   //    date.getUTCMinutes()
   // );
   
   const year = date.getUTCFullYear();
   const month = date.getUTCMonth();
   const day = date.getUTCDate();
   const hour = date.getUTCHours();
   const min = date.getUTCMinutes();
   const sec = setSeconds ? date.getUTCSeconds() : 0;

   const utcDate = Date.UTC(year, month, day, hour, min, sec);
   return new Date(utcDate).toISOString();
}

export function hasOwn(obj, prop) {
   return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function generateUUID() {
   var d = new Date().getTime();
   var uuid = 'xxxXXxxx-Xxxx-4xxX-yxxx-xXxXXXxxxXxx'.replace(/[xXy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      if (c == 'x')
         return r.toString(16);
      else if (c == 'X')
         return r.toString(16).toUpperCase();
      else
         return (r & 0x7 | 0x8).toString(16);
   });

   return uuid;
}
