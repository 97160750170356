<template>
<!-- TODO:
   - Make creationDate sortable
   - Main Info tab should be red
   - Move the v-form inside dialog v-card-text
-->
<!-- HISTORY:
   V230306.1: Moved the logic in V230302.1 to newImportClicked() + Set import name with the fileName without extension + Enabled SFTP tab.
   V230302.1: Add some logic in setImportName() to force the other tabs validations.
   V230301.1: Moved all fields except importName and fileDelimiter under the new settings tab + Defined TABS constant + Commented SAVE btn.
   V230227.1: Modified the logic for V230224.1 changes to make it work by adding file-select event on the Aref's side and consuming it here.
   V230224.1: In saveImport(), set import name automatically with the uploaded file path.
   V2212xx.1: Added a feature to delete rejected records.
   V230105.1: In the table, displayed processedRecords and purlProcessedRecords as zero instead of empty.
   V221222.1: Added rejectOnError switch (cannot be changed once set to true) + Shortened dedupHashMatching label & moved exportOnImportCompletion to the end + 
      Applied formatting to the processedRecords and purlProcessedRecords + Removed statuses texts and added tooltips instead.
   V221207.1: Made SFTP & PGP tab disabled (on Aref side).
   V221118.1: Added delete action for the imports with sftp.
   V221110.1: Applied/Modified styles to display contents properly after Aref's upgrade + Replaced hasOwnProperty() with hasOwn from mixins +
      Changed debug switch permission from pa to au + Changed the approach of parsing JWT token.
   V221021.2: Changed item-key from 'name' to '_id'.
   V221021.1: Added creator to the table's headers + Fixed SAVE btn disability by including SFTP & PGP forms to isImportFormValid.
   V221020.1: Made SFTP and PGP tab enabled and added SFTP fields + Added new fields to the expanded item + Added 2 new status values.
   08/16/22(B1.6): Added debug switch for power admin.
   06/06/22(B1.5): Made typeCasting switch disabled if it had been set to true + Added persistEvent switch.
   05/27/22(B1.4): Added typeCasting switch.
   05/02/22(B1.3): Implemented logout feature + Disabled Notifications and GPG tabs.
   04/14/22(B1.2): Commented Actions header (from Aref side).
   03/17/22(B1.1): Showed New Import btn only if the user has contact-imports-create policy + Changed the icon.
   02/25/22(B1.0): Made Refresh btn hidden until an import is available + Added hide-default-footer prop to v-data-table +
      Fixed the bug that didn't show the 1st item (new import) + Fixed the bug that didn't show imports when account was changed.
   02/17/22(B0.12): Changed 30 sec delay for purlProcessedRecords to 2 and added 20 sec for the processedRecords as well +
      Changed the layout of expanded-item to be 2 columns with Headers to be in one + Added PURLs fields to searchFields and set their isIndexed properties.
   02/15/22(B0.11): Added Tooltip to the processedRecords and purlProcessedRecords columns to show the processing time and speed.
   01/14/22(B0.10): Added purlLastActivityDate to the table.
   01/13/22(B0.9): shouldGeneratePurl & dedupHashMatching were allowed to be set during 1st import creation only.
   01/11/22(B0.8): Added dedupHashMatching to the Main Info tab.
   01/06/22(B0.7): Added PURL Status and PURL Processed Records to headers.
   07/26/21(B0.6): Added Creation Date, Processed Records, Start Date and Last Activity Date to searchFields.
   07/26/21(B0.5): Consumed BtFilterDefiner to provide search option + Added pagination logic +
      Removed aid from all methods params.
   03/24/21(B0.4): Merged Aref's code + Added _id and header + Set icon for Status.
   03/23/21(B0.3): In SaveImport(), only passed existed values instead of all.
   03/19/21(B0.2): Removed Config concept and implemented new rules.
   xx/xx/20(B0.1): 1st version/release.
-->
<!-- BUSINESS LOGICS:
   - {
      "sftp": {
         "sftpCredentials": {
            "host": "",
            "port": 22,
            "username": "",
            "password": ""
         },
         "sftpPath": "listServices", //folder
         "pgpCredentials": {
            "privateKey": "",
            "passPhrase": ""
         }
      },
      "status": "sftp",
      ...
     }
   - scope cannot be modified anymore if Import Level is selected.
   - purl and dedup columns cannot be modified if scope is 'account'.
   - purl and dedup columns are not needed if generatePURL is false.
-->
<v-container fluid class="px-3 py-3">
   <v-card>
      <v-card-title class="pl-2">
         <h1 class="title font-weight-bold grey--text darken-4 pl-2" style="color:#757575 !important">
            <!-- <v-icon class="pr-1">import_contacts</v-icon> -->
            <v-icon class="pr-1">file_download</v-icon>
            <span>Imports</span>
         </h1>
         <div class="flex-grow-1"></div>
         <div>
            <!-- <v-text-field single-line hide-details
               class="pt-0"
               label="Search"
               append-icon="search"
               :disabled="!imports || imports.length < 2"
               v-model="search"
            ></v-text-field> -->
            <v-btn v-if="imports.length"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="btnRefreshImportsDisabled || !imports.length"
               @click="getImports()"
            >REFRESH{{importsDisabledDuration ? ' in ' + importsDisabledDuration + ' sec' : ''}}
               <v-icon right dark>refresh</v-icon>
            </v-btn>
            <v-btn v-if="$store.getters.user.policies.includes('contact-imports-create')"
               x-small
               class="mr-2 mt-1"
               color="gray darken-1"
               :disabled="loadingImports"
               @click="newImportClicked()"
            >NEW IMPORT
               <v-icon right dark>add</v-icon>
            </v-btn>
         </div>
      </v-card-title>
      <!-- <v-card-text v-if="loadingConfig" class="v-data-table__empty-wrapper">Loading account configuration. Please wait...</v-card-text> -->
      <v-card-text class="py-0">
         <bt-filter-wrapper-with-panel
            :closed-on-load="true"
            :fields="searchFields"
            :included-tabs="['standard']"
            :is-admin="jwt.pa"
            :max="searchFields.length"
            :preselected-fields="[]"
            :should-init="shouldInitFilterDefiner2"
            :std-field-values="searchFieldsValues"
            v-model="filter2"
            @filter-change="filterChanged2"
         ></bt-filter-wrapper-with-panel>
      </v-card-text>
      <v-card-text>
         <!-- :hide-default-footer="imports.length < 11" -->
         <v-data-table dense fixed-header show-expand
            class="elevation-1"
            item-key="_id"
            :footer-props="{
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }"
            :headers="headers"
            :hide-default-footer="importsCount <= 5"
            :items="imports"
            :items-per-page="5"
            :loading="loadingImports"
            :loading-text="$t('loading-text')"
            :no-data-text="$t('no-data-text', { value: 'imports' })"
            :no-results-text="$t('no-results-text', { value: 'imports' })"
            :options.sync="options"
            :search="search"
            :server-items-length="importsCount"
            :single-expand="false"
         >
            <template v-slot:[`item.creationDate`]="{ item }">
               {{ formatDate(item.creationDate, true) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
               <!-- <v-icon :color="getIcon(item.status).color">{{getIcon(item.status).icon}}</v-icon>
               <span :text--color="getIcon(item.status).color"> {{ item.status }}</span> -->
               <!-- <v-tooltip top :color="getIcon(item.status).color"> -->
               <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">
                        <v-icon :color="getIcon(item.status).color"
                        >{{getIcon(item.status).icon}}</v-icon>
                     </span>
                  </template>
                  <span>{{item.status}}</span>
               </v-tooltip>
            </template>
            <template v-slot:[`item.processedRecords`]="{ item }">
               <v-tooltip v-if="item.processedRecords"
                  top color="black"
               >
                  <template v-slot:activator="{ on, attrs }">
                     <!-- <span v-bind="attrs" v-on="on">{{new Intl.NumberFormat().format(item.processedRecords)}}</span> -->
                     <span v-bind="attrs" v-on="on">
                        <!-- <v-btn v-if="item.status === 'rejected'"
                           small text
                           color="blue darken-1"
                           @click="processedRecordsClicked(item)"
                        >
                           {{formatNumber(item.processedRecords)}}
                        </v-btn> -->
                        <!-- <v-btn v-else
                           small text
                        > -->
                           {{formatNumber(item.processedRecords)}}
                        <!-- </v-btn> -->
                     </span>
                  </template>
                  <span>Processing Time: {{calculateProcessingTime(item.lastActivityDate, item.startDate, 0)}}</span>
                  <br>
                  <span>Processing Speed: {{calculateProcessingSpeed(item.processedRecords, item.lastActivityDate, item.startDate, 0)}} Rec/Hr</span>
               </v-tooltip>
               <span v-else>0</span>
               <!-- <span v-else>{{item.hasOwnProperty('processedRecords') ? new Intl.NumberFormat().format(item.processedRecords) : ''}}</span> -->
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
               {{ formatDate(item.startDate, true) }}
            </template>
            <template v-slot:[`item.lastActivityDate`]="{ item }">
               {{ formatDate(item.lastActivityDate, true) }}
            </template>
            <template v-slot:[`item.purlStatus`]="{ item }">
               <!-- <v-icon :color="getIcon(item.purlStatus).color">{{getIcon(item.purlStatus).icon}}</v-icon><span :text--color="getIcon(item.purlStatus).color"> {{ item.purlStatus }}</span> -->
               <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">
                        <v-icon :color="getIcon(item.purlStatus).color"
                        >{{getIcon(item.purlStatus).icon}}</v-icon>
                     </span>
                  </template>
                  <span>{{item.purlStatus}}</span>
               </v-tooltip>
            </template>
            <template v-slot:[`item.purlProcessedRecords`]="{ item }">
               <v-tooltip v-if="item.purlProcessedRecords"
                  top color="black"
               >
                  <template v-slot:activator="{ on, attrs }">
                     <span v-bind="attrs" v-on="on">{{formatNumber(item.purlProcessedRecords)}}</span>
                  </template>
                  <span>Processing Time: {{calculateProcessingTime(item.purlLastActivityDate, item.purlStartDate, 0)}}</span>
                  <br>
                  <span>Processing Speed: {{calculateProcessingSpeed(item.purlProcessedRecords, item.purlLastActivityDate, item.purlStartDate, 0)}} Rec/Hr</span>
               </v-tooltip>
               <span v-else>0</span>
            </template>
            <template v-slot:[`item.purlLastActivityDate`]="{ item }">
               {{ formatDate(item.purlLastActivityDate, true) }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
               <v-icon v-if="item.status.indexOf('sftp') === 0"
                  small
                  :disabled="!canDeleteImport"
                  @click="deleteImport(item)"
               >delete</v-icon>
               <!-- <v-icon small
                  :disabled="item.status !='waiting' && item.status !='recovery'"
                  @click="updateImport(item)"
               >cancel</v-icon> -->
            </template>

            <template v-slot:expanded-item="{ item }">
               <td colspan="5" class="py-2" valign="top" dense>
                  <ul>
                     <li>
                        <span class="expanded-header">PURLs: </span>
                        <span class="expanded-content">{{item.generatePURL ? item.purlColumns.join(', ') : ''}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Dedups: </span>
                        <span class="expanded-content">{{item.generatePURL ? item.dedupColumns.join(', ') : ''}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Notification Emails: </span>
                        <span class="expanded-content">{{item.notificationEmails ? item.notificationEmails.join(', ') : ''}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Latitude Column: </span>
                        <span class="expanded-content">{{item.latitudeColumn}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Longitude Column: </span>
                        <span class="expanded-content">{{item.longitudeColumn}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Generate PURL: </span>
                        <span class="expanded-content text-capitalize">{{item.generatePURL}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">PURL Generation Scope: </span>
                        <span class="expanded-content text-capitalize">{{item.purlGenerationScope}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Same PURL: </span>
                        <span class="expanded-content text-capitalize">{{item.dedupHashMatching}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Cast Types: </span>
                        <span class="expanded-content text-capitalize">{{item.typeCasting || false}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Persist Event: </span>
                        <span class="expanded-content text-capitalize">{{item.persistEvent || false}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Reject on Error: </span>
                        <span class="expanded-content text-capitalize">{{item.rejectOnError}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Auto Export: </span>
                        <span class="expanded-content text-capitalize">{{item.exportOnImportCompletion}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Batch Size: </span>
                        <span class="expanded-content">{{item.processBatchSize}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">File Delimiter: </span>
                        <span class="expanded-content">{{getDelimiterDesc(item.fileDelimiter)}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Debug: </span>
                        <span class="expanded-content text-capitalize">{{item.debug}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">File Path: </span>
                        <span class="expanded-content text-capitalize">{{item.filePath}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">DB Name: </span>
                        <span class="expanded-content text-capitalize">{{item.databaseName}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Recovery Count: </span>
                        <span class="expanded-content">{{item.recoveryCount}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">Recovery Error: </span>
                        <span class="expanded-content">{{item.recoveryError}}</span>
                     </li>
                     <li>
                        <span class="expanded-header">ID: </span>
                        <span class="expanded-content text-capitalize">{{item._id}}</span>
                     </li>
                  </ul>
               </td>
               <td colspan="5" class="py-2" valign="top" dense>
                  <ul>
                     <li>
                        <span class="expanded-header">Header({{item.header ? item.header.length : 0}}): </span>
                        <span class="expanded-content">{{item.header ? item.header.join(', ') : ''}}</span>
                     </li>
                     <li v-if="item.sftp">
                        <span class="expanded-header">SFTP Host: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.host}}</span>
                     </li>
                     <li v-if="item.sftp && item.sftp.sftpPath">
                        <span class="expanded-header">SFTP Path: </span>
                        <span class="expanded-content">{{item.sftp.sftpPath}}</span>
                     </li>
                     <li v-if="item.sftp && item.sftp.sftpCredentials.port">
                        <span class="expanded-header">SFTP Port: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.port}}</span>
                     </li>
                     <li v-if="item.sftp">
                        <span class="expanded-header">SFTP Username: </span>
                        <span class="expanded-content">{{item.sftp.sftpCredentials.username}}</span>
                     </li>
                     <li v-if="item.sftp">
                        <span class="expanded-header">Has PGP Credentials: </span>
                        <span class="expanded-content">{{item.sftp.pgpCredentials ? true : false}}</span>
                     </li>
                  </ul>
               </td>
            </template>
         </v-data-table>
      </v-card-text>
      <!-- <v-card-text v-if="!configData._id && !loadingConfig">You need to configure your account first.</v-card-text> -->
   </v-card>

   <v-form lazy-validation
      ref="mainForm"
      v-model="isMainFormValid"
   >
      <v-dialog no-click-animation persistent
         max-width="1080px"
         v-model="dialogImport"
      >
         <v-card flat>
            <v-card-title class="title grey--text darken-4 font-weight-bold pb-2">Creating New Import:</v-card-title>
            <v-card-text
               class="pb-0"
               :loading="loadingNewImport"
            >
               <v-tabs
                  class="elevation-2"
                  background-color="grey lighten-2 accent-4"
                  slider-color="black"
                  v-model="tab"
               >
                  <v-tab 
                     :class="isMainFormValid ? '' : 'red--text'"
                  >General</v-tab>
                  <v-tab>Settings</v-tab>
                  <v-tab v-if="formData.shouldGeneratePurl"
                     :class="isPurlsFormValid ? '' : 'red--text'"
                  >PURL Columns</v-tab>
                  <v-tab v-if="formData.shouldGeneratePurl"
                     :class="isDedupsFormValid ? '' : 'red--text'"
                  >Dedup Columns</v-tab>
                  <v-tab
                     :class="isSftpFormValid && isPgpFormValid ? '' : 'red--text'"
                  >SFTP & PGP</v-tab>
                  <v-tab disabled
                     :class="isEmailsFormValid ? '' : 'red--text'"
                  >Notification Emails</v-tab>

                  <v-tabs-items v-model="tab">
                     <v-tab-item><!-- General -->
                        <v-card flat tile>
                           <v-card-text>
                              <v-row class="pb-2">
                                 <v-col xs="12" sm="12" md="9" class="py-2">
                                       <!-- :placeholder="`${hasError('importName') ? 'import name' : ''}`" -->
                                    <v-text-field autofocus dense persistent-hint required
                                       v-model="formData.importName"
                                       ref="importName"
                                       hint="* Import Name"
                                       placeholder="enter a name for the import or upload a file"
                                       :counter="100"
                                       :maxlength="100"
                                       :rules="[rules.required, rules.minLength, rules.duplicate]"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="3" class="py-2">
                                    <v-select dense persistent-hint hide-selected required
                                       ref="delimiter"
                                       v-model="formData.delimiter"
                                       hint="* File Delimiter"
                                       :items="delimiterItems"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item><!-- Settings -->
                        <v-card flat tile>
                           <v-card-text>
                              <v-row>
                                 <v-col class="pt-1 pb-0 font-weight-bold">Please note that "Generate PURL" and "Same PURL" options cannot be changed after the 1st import is submitted. Also, "Cast Types", "Persist Event", and "Reject on Error" options cannot be changed once they are set to true.</v-col>
                              </v-row>
                              <v-row class="pt-2">
                                 <v-col xs="12" sm="12" md="2" class="py-0 pr-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Generate PURL"
                                       :disabled="hasImport"
                                       v-model="formData.shouldGeneratePurl"
                                       @change="generatePurlChanged"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0 pl-5 pr-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Same PURL"
                                       :disabled="!formData.shouldGeneratePurl || hasImport"
                                       v-model="formData.dedupHashMatching"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Cast Types"
                                       :disabled="imports && imports.length > 0 && imports[0].typeCasting"
                                       v-model="formData.shouldCastTypes"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Persist Event"
                                       :disabled="imports && imports.length > 0 && imports[0].persistEvent"
                                       v-model="formData.shouldPersistEvent"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0 pr-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Reject on Error"
                                       :disabled="imports && imports.length > 0 && imports[0].rejectOnError"
                                       v-model="formData.shouldRejectOnError"
                                    ></v-switch>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0">
                                    <v-switch
                                       class="mx-0 mt-2 mb-0 pb-0"
                                       label="Auto Export"
                                       v-model="formData.shouldExport"
                                    ></v-switch>
                                 </v-col>
                              </v-row>
                              <v-row class="pt-1 pb-3">
                                 <v-col xs="12" sm="12" md="3" class="py-0">
                                    <v-text-field clearable dense persistent-hint
                                       v-model="formData.latitudeColumn"
                                       hint="Latitude Column"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="3" class="py-0">
                                    <v-text-field clearable dense persistent-hint
                                       v-model="formData.longitudeColumn"
                                       hint="Longitude Column"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="2" class="py-0">
                                    <v-select dense persistent-hint hide-selected required
                                       ref="batchSize"
                                       v-model="formData.batchSize"
                                       hint="* Import Batch Size"
                                       :items="batchSizeItems"
                                    ></v-select>
                                 </v-col>
                                 <v-col xs="12" sm="12" md="4" class="py-0">
                                    <v-select dense persistent-hint hide-selected required
                                       ref="scope"
                                       v-model="formData.scope"
                                       :hint="`* PURL Generation Scope${formData.scope==='account' ? '' : ': Cannot be changed anymore'}`"
                                       :disabled="!canModifyScope"
                                       :items="scopeItems"
                                    ></v-select>
                                 </v-col>
                              </v-row>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-if="formData.shouldGeneratePurl"><!-- PURL Columns -->
                        <v-card flat tile>
                           <v-card-text class="py-2 px-0">
                              <bt-dynamic-text-fields
                                 field-label=""
                                 field-placeholder="purl column"
                                 field-type="string"
                                 :debug="debug"
                                 :field-dense="true"
                                 :field-disabled="imports.length > 0 && imports[0].purlGenerationScope === 'account' && imports[0].purlColumns && imports[0].purlColumns.length > 0 && formData.scope === 'account'"
                                 :field-max="3"
                                 :field-outlined="false"
                                 :field-required="true"
                                 :should-init="formData.shouldInitPurls"
                                 v-model="formData.purls"
                                 @form-validation="purlsFormValidated"
                              ></bt-dynamic-text-fields>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item v-if="formData.shouldGeneratePurl"><!-- Dedup Columns -->
                        <v-card flat tile>
                           <v-card-text class="py-2 px-0">
                              <bt-dynamic-text-fields
                                 field-placeholder="dedup column"
                                 field-type="string"
                                 field-validation=""
                                 :debug="debug"
                                 :field-dense="true"
                                 :field-disabled="imports.length > 0 && imports[0].purlGenerationScope === 'account' && imports[0].dedupColumns && imports[0].dedupColumns.length > 0 && formData.scope === 'account'"
                                 :field-max="5"
                                 :field-required="true"
                                 :should-init="formData.shouldInitDedups"
                                 v-model="formData.dedups"
                                 @form-validation="dedupsValidated"
                              ></bt-dynamic-text-fields>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>

                     <v-tab-item><!-- SFTP and PGP -->
                        <v-expansion-panels multiple focusable
                           class="px-4 py-4"
                           v-model="panels"
                        >
                           <v-expansion-panel readonly
                              class="px-0"
                           >
                              <v-expansion-panel-header hide-actions
                                 class="py-0 font-weight-bold"
                              >SFTP Settings</v-expansion-panel-header>
                              <v-expansion-panel-content class="px-0">
                                 <v-card flat tile>
                                    <v-card-text class="px-0 py-5">
                                       <v-form lazy-validation
                                          ref="sftpForm"
                                          v-model="isSftpFormValid"
                                       >
                                          <v-row class="py-0">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="pt-1 pb-0">
                                                <v-text-field clearable dense persistent-hint
                                                   ref="host"
                                                   autocomplete="off"
                                                   label="Host"
                                                   v-model="formData.host"
                                                   @keyup="hostChanged(formData.host)"
                                                   @click:clear="hostCleared"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col v-if="formData.host" class="pt-1"
                                                cols="12" xs="12" sm="12" md="4" lg="4"
                                             >
                                                <v-text-field dense persistent-hint
                                                   ref="folder"
                                                   autocomplete="off"
                                                   label="Folder"
                                                   v-model="formData.sftpPath"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col v-if="formData.host" class="pt-1"
                                                cols="12" xs="12" sm="12" md="2" lg="2"
                                             >
                                                <v-text-field dense persistent-hint
                                                   ref="port"
                                                   autocomplete="off"
                                                   label="Port"
                                                   type="number"
                                                   min="1"
                                                   v-model="formData.port"
                                                ></v-text-field>
                                             </v-col>
                                          </v-row>
                                          <v-row v-if="formData.host" class="py-0">
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                                                <v-text-field dense persistent-hint
                                                   ref="sftpUsername"
                                                   autocomplete="off"
                                                   label="* User Name"
                                                   :rules="[rules.required]"
                                                   v-model="formData.sftpUsername"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0">
                                                <v-text-field counter dense persistent-hint
                                                   ref="sftpPass"
                                                   autocomplete="off"
                                                   label="* Password"
                                                   :type="showPassword ? 'text' : 'password'"
                                                   :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                                   :rules="[rules.required]"
                                                   @click:append="showPassword = !showPassword"
                                                   v-model="formData.sftpPass"
                                                ></v-text-field>
                                             </v-col>
                                          </v-row>
                                       </v-form>
                                    </v-card-text>
                                 </v-card>
                              </v-expansion-panel-content>
                           </v-expansion-panel>                                       

                           <v-expansion-panel
                              readonly
                              class="px-0 pb-0"
                           >
                              <v-expansion-panel-header hide-actions
                                 class="py-0 font-weight-bold"
                              >PGP Settings</v-expansion-panel-header>
                              <v-expansion-panel-content class="px-0">
                                 <v-card flat tile>
                                    <v-card-text class="px-0 pt-5 pb-2">
                                       <v-form lazy-validation
                                          ref="pgpForm"
                                          v-model="isPgpFormValid"
                                       >
                                          <v-row class="py-0">
                                             <v-col xs="12" sm="12" md="6">
                                                <v-text-field clearable dense persistent-hint
                                                   label="Passphrase"
                                                   :disabled="!formData.host || !isSftpFormValid"
                                                   @keyup="passphraseChanged"
                                                   @click:clear="passphraseCleared"
                                                   v-model="formData.passphrase"
                                                ></v-text-field>
                                             </v-col>
                                             <v-col v-if="formData.passphrase"
                                                xs="12" sm="12" md="6" class="pt-5 pb-0">
                                                <v-btn block
                                                   color="primary"
                                                   :disabled="!isSftpFormValid"
                                                   @click="generatePgpKeys"
                                                >Generate Key</v-btn>
                                             </v-col>
                                          </v-row>

                                          <v-row v-if="formData.passphrase" v-show="formData.publicKey"
                                             class="py-0"
                                          >
                                             <v-col xs="12" sm="12" md="6" class="py-0">
                                                <v-textarea dense hide-details
                                                   class="py-0 my-0 caption"
                                                   rows="4"
                                                   :rules="[rules.required]"
                                                   v-model="formData.publicKey"
                                                ></v-textarea>
                                             </v-col>
                                             <v-col xs="12" sm="12" md="6" class="pt-0 pb-0">
                                                <v-btn v-if="isClipboard"
                                                   block
                                                   class="caption"
                                                   color="primary"
                                                   :hint="copyToClipboardHint"
                                                   @click="copyToClipboard"
                                                >
                                                   <v-icon dark left class="pl-1">content_copy</v-icon>
                                                   <span>{{copyToClipboardHint}}</span>
                                                </v-btn>
                                             </v-col>
                                          </v-row>
                                       </v-form>
                                    </v-card-text>
                                 </v-card>
                              </v-expansion-panel-content>
                           </v-expansion-panel>
                        </v-expansion-panels>
                     </v-tab-item>

                     <v-tab-item><!-- Notification Emails -->
                        <v-card flat tile>
                           <v-card-text class="py-2 px-0">
                              <bt-dynamic-text-fields
                                 field-placeholder="email"
                                 field-type="email"
                                 :debug="debug"
                                 :field-dense="true"
                                 :field-required="false"
                                 :should-init="formData.shouldInitEmails"
                                 v-model="formData.emails"
                                 @form-validation="emailsValidated"
                              ></bt-dynamic-text-fields>
                           </v-card-text>
                        </v-card>
                     </v-tab-item>
                  </v-tabs-items>
               </v-tabs>
            </v-card-text>

            <v-card-actions :class="actionsClass">
               <v-switch v-if="jwt.au"
                  class="mx-0 my-0 pl-5 py-0"
                  label="Debug"
                  v-model="formData.debug"
               ></v-switch>
               <div class="flex-grow-1"></div>
               <v-btn text
                  class="pl-0 pr-3"
                  color="blue darken-1"
                  @click="cancelImport"
               >Cancel</v-btn>
               <!-- <v-btn v-if="!isActualEndpoint"
                  text
                  color="blue darken-1"
                  :disabled="!isImportFormValid"
                  @click="saveImport"
               >Save</v-btn> -->
               <import-file-upload
                  :notActive="!isImportFormValid"
                  :noUpload="formData.host != ''"
                  @file-selected="setImportName"
                  @uploaded="saveImport"
               />
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-form>

   <v-dialog no-click-animation persistent scrollable
      max-width="640px"
      v-model="dialogDelete"
   >
      <v-card>
         <v-card-title class="title grey--text darken-4 font-weight-bold pt-3 pb-0"
         >            
            <v-icon color="error" class="pr-1">warning_amber</v-icon>Deleting {{formatNumber(currImport.processedRecords)}} records of <span class="font-italic px-1">{{currImport.name}}</span>:
         </v-card-title>

         <v-card-text v-if="isAnotherDelete"
            class="pt-4 pb-0 error--text"
         >
            A delete request is already in progress. Please try again after a while.
         </v-card-text>
         <v-card-text v-else
            class="pb-0"
         >
            <div v-if="errMsg" class="red--text">{{errMsg}}</div>
            <v-text-field outlined required persistent-hint
               class="pt-3 pb-0"
               ref="currProcessedRecords"
               hint="Please be mindful! This processes cannot be stopped or cancelled once submitted."
               label="* Number of Processed Records to be deleted"
               placeholder="enter the number of processed records of the import"
               type="number"
               :min="currImport.processedRecords"
               :max="currImport.processedRecords"
               v-model="currProcessedRecords"
            ></v-text-field>
            <div v-if="isDeleteInProcess"
               class="error--text font-weight-bold"
            >
               <v-progress-circular indeterminate
                  color="red"
                  :size="20"
                  :width="2"
               ></v-progress-circular>
               Please wait while your delete request is being processed: {{deleteElapsedTime}}
            </div>
         </v-card-text>

         <v-card-actions class="pt-0 pr-4">
            <div class="flex-grow-1"></div>
            <v-btn text
               color="blue darken-1"
               @click="closeDialogDelete"
            >Cancel</v-btn>
            <v-btn v-if="!isAnotherDelete"
               text
               color="blue darken-1"
               :disabled="currProcessedRecords != currImport.processedRecords"
               @click="deleteClicked"
            >Delete</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>

</v-container>
</template>

<script>
import BtFilterWrapperWithPanel from './BtFilterWrapperWithPanel.vue';
import BtDynamicTextFields from './BtDynamicTextFields.vue';
import { APIService } from '../services/cs-api-service.js';
import { addSeconds, differenceInHours, differenceInMinutes, differenceInSeconds, format, parseISO } from "date-fns";
import ImportFileUpload from './ImportFileUpload.vue';
// import { generateKey } from 'openpgp/lightweight';
import { generateKey } from 'openpgp';
import { hasOwn } from '../mixins/bt-mixin.js';
import { BtHelpers } from '../services/bt-helpers.js';

const TABS = { general: 0, settings: 1, purl: 2, dedup: 3, sftp: 4, emails: 5 };

class FormData {
   constructor(initData, shouldInit) {
      this.scope = initData.purlGenerationScope || 'account';
      this.purls = initData.purlColumns || [];
      this.shouldInitPurls = shouldInit;

      this.dedups = initData.dedupColumns || [];
      this.shouldInitDedups = shouldInit;

      this.emails = initData.notificationEmails || [];
      this.shouldInitEmails = shouldInit;

      this.validations = initData.columnValidation || [];
      this.shouldInitValidations = shouldInit;

      this.indexes = initData.indexObject || {};
      this.shouldInitIndexes = shouldInit;

      this.mappings = initData.columnMapping || {};
      this.shouldInitMappings = shouldInit;

      this.latitudeColumn = initData.latitudeColumn || '';
      this.longitudeColumn = initData.longitudeColumn || '';
      this.delimiter = initData.fileDelimiter || ',';
      this.batchSize = initData.processBatchSize || 100;
      this.shouldGeneratePurl = hasOwn(initData, 'generatePURL') ? initData.generatePURL : true;
      this.shouldExport = hasOwn(initData, 'exportOnImportCompletion') ? initData.exportOnImportCompletion : false;
      this.shouldCastTypes = hasOwn(initData, 'typeCasting') ? initData.typeCasting : false;
      this.shouldPersistEvent = hasOwn(initData, 'persistEvent') ? initData.persistEvent : false;
      this.importName = '';
      this.dedupHashMatching = hasOwn(initData, 'dedupHashMatching') ? initData.dedupHashMatching : false;
      this.shouldRejectOnError = hasOwn(initData, 'rejectOnError') ? initData.rejectOnError : false;

      // this.host = initData.sftp ? initData.sftp.sftpCredentials.host : '';
      // this.port = initData.sftp ? initData.sftp.sftpCredentials.port : '';
      // this.sftpUsername = initData.sftp ? initData.sftp.sftpCredentials.username : '';
      // this.sftpPass = initData.sftp ? initData.sftp.sftpCredentials.password : '';
      // this.sftpPath = initData.sftp ? initData.sftp.sftpPath : '';
      // this.privateKey = initData.sftp ? initData.sftp.pgpCredentials.privateKey : '';
      // this.publicKey = initData.sftp ? initData.sftp.pgpCredentials.publicKey : '';
      // this.passPhrase = initData.sftp ? initData.sftp.pgpCredentials.passPhrase : '';
      this.host = '';
      this.port = 22;
      this.sftpUsername = '';
      this.sftpPass = '';
      this.sftpPath = '';

      this.passPhrase = '';
      this.privateKey = '';
      this.publicKey = '';

      this.debug = false;
   }
}

export default {
   name: "CsImports",

   components: {
      BtFilterWrapperWithPanel,
      BtDynamicTextFields,
      ImportFileUpload
   },

   props: {
      debug: {
         type: Boolean,
         default: false
      },
      isActualEndpoint: {
         type: Boolean,
         default: true
      }
   },

   data() {
      return {
         jwt: {},
         apiService: null,
         tab: null,
         tabSftp: null,
         formData: new FormData({}, false),
         imports: [],
         rules: {
            required: value => !!value || "Value is required!",
            minLength: value => value.length >= 5 || "Name should have more than 5 charecters",
            duplicate: value => this.imports.filter(imprt => imprt.name === value).length === 0 || 'Value is duplicate!'
         },
         headers: [
            { text: 'Name', value: 'name', align: 'left', sortable: true },
            { text: 'Creation', value: 'creationDate', align: 'left', sortable: false },
            { text: 'Creator', value: 'creator', align: 'left', sortable: true },
            { text: 'Status', value: 'status', align: 'left', sortable: true },
            { text: 'Processed Records', value: 'processedRecords', align: 'left', sortable: true },
            { text: 'Start', value: 'startDate', align: 'left', sortable: true },
            { text: 'Last Activity', value: 'lastActivityDate', align: 'left', sortable: true },
            { text: 'PURL Status', value: 'purlStatus', align: 'left', sortable: true },
            { text: 'PURL Processed Records', value: 'purlProcessedRecords', align: 'left', sortable: true },
            { text: 'PURL Last Activity', value: 'purlLastActivityDate', align: 'left', sortable: true },
            { text: 'Actions', value: 'action', align: 'right', sortable: false }
         ],
         batchSizeItems: [
            { text: '25', value: 25 },
            { text: '50', value: 50 },
            { text: '75', value: 75 },
            { text: '100', value: 100 }
         ],
         delimiterItems: [
            { text: 'Comma', value: ',' },
            { text: 'Tab', value: '\t' },
            { text: 'Pipe', value: '|' }
         ],
         scopeItems: [
            { text: 'Account Level', value: 'account' },
            { text: 'Import Level', value: 'import' }
         ],
         search: '',
         tdHeader: 'font-weight-bold font-italic',
         tdContent: 'font-italic',
         importsDisabledDuration: 0,
         btnRefreshImportsDisabled: true,
         loadingImports: false,
         loadingNewImport: false,
         dialogImport: false,
         dialogUpload: false,
         isMainFormValid: false,
         isPurlsFormValid: false,
         isDedupsFormValid: false,
         isEmailsFormValid: true,
         isSftpFormValid: true,
         isPgpFormValid: true,
         canModifyScope: false,
         importsCount: 0,
         options: {},
         shouldInitFilterDefiner2: false,
         filter2: {
            standard: [{ $match: {} }],
            // columns: []
         },
         searchFields: [
            { text: 'Name', value: 'name', type: 'string', isIndexed: true },
            { text: 'Creation Date', value: 'creationDate', type: 'date', isIndexed: true },
            { text: 'Status', value: 'status', type: 'string', isIndexed: true },
            { text: 'Processed Records', value: 'processedRecords', type: 'number', isIndexed: true },
            { text: 'Start Date', value: 'startDate', type: 'date', isIndexed: true },
            { text: 'Last Activity Date', value: 'lastActivityDate', type: 'date', isIndexed: true },
            { text: 'PURL Status', value: 'purlStatus', type: 'string', isIndexed: true },
            { text: 'PURL Processed Records', value: 'purlProcessedRecords', type: 'number', isIndexed: true },
            { text: 'PURL Last Activity Date', value: 'purlLastActivityDate', type: 'date', isIndexed: true }
         ],
         searchFieldsValues: {
            status: ['waiting','processing','sftp', 'sftp-processing', 'completed','recovery','rejected'],
            purlStatus: ['waiting','processing','completed','recovery','rejected']
         },
         hasImport: false,
         panels: [0, 1],
         showPassword: false,
         isClipboard: false,
         copyToClipboardHint: '',
         actionsClass: 'mr-1 pr-5',
         btHelpers: null,
         isAnotherDelete: null,
         dialogDelete: false,
         errMsg: null,
         currImport: {},
         currProcessedRecords: null,
         isDeleteInProcess: null,
         deleteElapsedTime: null,
         firstLoad: true
      }
   },

   computed: {
      token() {
         return this.$store.getters.token;
      },

      isImportFormValid() {
         return this.formData.importName && this.isMainFormValid &&
            this.isEmailsFormValid && this.isSftpFormValid && this.isPgpFormValid &&
            (this.formData.shouldGeneratePurl ? this.isPurlsFormValid && this.isDedupsFormValid : true);
      },

      canDeleteImport() {
         return this.$store.getters.user.policies.includes('contact-import-delete');
      }
   },

   watch: {
      token() {
         // alert('in watch for token');
         this.init();
         this.nextAction();
      },

      options: {
         handler (val) {
            // alert('in watch: options=' + JSON.stringify(val));
            if (val.sortBy.length > 0) {
               const sort = {};
               sort[val.sortBy[0]] = val.sortDesc[0] ? -1 : 1;
               this.filter2.sort = sort;
            }
            this.getImports();
         }
      },

      tab: {
         handler (val) {
            // alert('in watch: tab=' + val);
            if (val === TABS.general) {   //0
               setTimeout(() => {
                  this.$refs.importName.focus();
               }, 10);
            } else if (val === TABS.sftp) { //4
               setTimeout(() => {
                  this.$refs.host.focus();
               }, 10);
            }
         }
      }
   },

   methods: {
      log(msg) {
         if (this.debug)
            console.log(`-----CsImport V230306.1 says => ${msg}`);
      },

      logout() {
         this.$router.push('/');
      },

      hasError(ref) {
         let res = false;
         if (!this.isMainFormValid && this.$refs[ref]) {
            // if (ref === 'importName') alert('isDirty=' + this.$refs[ref].isDirty);
            res = this.$refs[ref].hasError;
         }

         return res;
      },

      formatNumber(number) {
         return new Intl.NumberFormat().format(number);
      },

      async init() {
         try {
            // alert('in init(): token=' + this.token);
            this.imports = [];

            if (this.token) {
               // const JWT = JSON.parse(atob(this.token.split('.')[1]));
               // this.jwt = {
               //    aid: JWT.aid,
               //    paid: JWT.paid,
               //    pa: JWT.pa,
               //    pu: JWT.pu,
               //    au: JWT.au,
               //    email: JWT.email
               // };
               this.jwt = JSON.parse(Buffer.from(this.token.split('.')[1], 'base64'));
               this.log('in init(): jwt=' + JSON.stringify(this.jwt));
               this.btHelpers = new BtHelpers(this.token, this.isActualEndpoint, this.debug);
               this.apiService = new APIService(this.jwt, this.token, this.debug, this.isActualEndpoint);
               this.importsCount = await this.getImportsCount(this.filter2);
               this.hasImport = this.importsCount > 0 ? true : false;
               this.shouldInitFilterDefiner2 = true;
            } else
               this.jwt = {};
         } catch (error) {
            alert('Exception while parsing token: ' + error.message);
         }
      },

      async getImportsCount() {
         this.loadingImports = true;
         let result = await this.apiService.getImportsCount(this.filter2);
         this.loadingImports = false;
         if (result.logout)
            this.logout();

         return result.message ? 0 : result.data;
      },

      async getImports() {
         // alert(`in getImports(): filter2=${JSON.stringify(this.filter2)}`);
         this.loadingImports = true;
         this.btnRefreshImportsDisabled = true;
         this.imports = [];
         let result = await this.apiService.getImports(this.filter2, this.options.itemsPerPage, this.options.page);
         if (result.logout)
            this.logout();
            
         if (!result.message) {
            this.imports = result.data;
         }

         if (this.imports.length === 0 || this.imports[0].purlGenerationScope === 'account')
            this.canModifyScope = true;
         else
            this.canModifyScope = false;

         if (this.imports.length || result.message) {
            this.importsDisabledDuration = 10;
            setTimeout(() => {
               this.btnRefreshImportsDisabled = false;
            }, 10000);

            const setIntervalImports = setInterval(() => {
               if (this.importsDisabledDuration > 0)
                  this.importsDisabledDuration--;
               else
                  clearInterval(setIntervalImports);
            }, 1000);
         }

         this.loadingImports = false;
      },

      getIcon(status) {
         switch (status) {
            case 'waiting':
               return { color: 'grey', icon: 'schedule' };
            case 'processing':
               return { color: 'blue', icon: 'hourglass_empty' };
            case 'sftp':
               return { color: 'grey lighten-2', icon: 'cloud' };  //mdi-cloud-outline
            case 'sftp-processing':
               return { color: 'blue', icon: 'cloud_off' }; //mdi-cloud-off-outline
            case 'completed':
               return { color: 'green', icon: 'done' };
            case 'recovery':
               return { color: 'orange', icon: 'restore' };
            case 'rejected':
               return { color: 'red', icon: 'clear' };
            default:
               return { color: '', icon: '' };
         }
      },

      async updateImport(importData) {
         if (confirm(`Are you sure you want to cancel '${importData.name}'?`)) {
            this.log('in updateImport(): importData=' + JSON.stringify(importData));
            this.loadingImports = true;
            const result = await this.apiService.updateImport(importData);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               // importData.status = result.data.status;
               // importData.recoveryError = result.data.recoveryError;
               // importData.lastActivityDate = result.data.lastActivityDate;
               importData = result.data;
               this.$emit('snackbar-event', `'${importData.name}' import was cancelled.`);
            }
            this.loadingImports = false;
         }
      },

      async deleteImport(importData) {
         if (confirm(`Are you sure you want to DELETE '${importData.name}'?`)) {
            this.loadingImports = true;
            this.log('in deleteImport(): importData=' + JSON.stringify(importData));
            const result = await this.apiService.deleteImport(importData._id);
            if (result.logout)
               this.logout();
            else if (!result.message) {
               const ind = this.imports.indexOf(importData);
               this.imports.splice(ind, 1);
               this.$emit('snackbar-event', `'${importData.name}' import was deleted.`);
            }
            this.loadingImports = false;
         }
      },

      getDelimiterDesc(delimiter) {
         return this.delimiterItems.filter(d => d.value === delimiter)[0].text;
      },

      formatDate(date, withTime) {
         if (date) {
            // alert('in formatDate(): date='+date+'\nwithTime=' + withTime + '\nparseISO='+parseISO(date));
            const formatteddate = format(parseISO(date), 'M/d/yyyy h:mm:ss a');
            // const dateParts = formatteddate.split(' ');
            // if (withTime) return dateParts[0] + '\n' + dateParts[1] + ' ' + dateParts[2];
            if (withTime) return formatteddate;
            else return formatteddate.split(' ')[0];
         }
      },

      calculateProcessingTime(dateEnded, dateStarted, extraSeconds) {
         const isoEnded = parseISO(dateEnded);
         const isoStarted = addSeconds(parseISO(dateStarted), extraSeconds || 0);
         const h = differenceInHours(isoEnded, isoStarted);
         const min = differenceInMinutes(isoEnded, isoStarted);
         const m = min - h * 60;
         const s = differenceInSeconds(isoEnded, isoStarted) - h * 3600 - m * 60;
         let diff = '';
         if (h) diff = h + 'h ';
         if (min) diff += m + 'm ';
         diff += s + 's';
         return diff;
      },

      calculateProcessingSpeed(processedRecs, dateEnded, dateStarted, extraSeconds) {
         const cntPerHour = this.formatNumber(Math.round(processedRecs * 3600 / differenceInSeconds(parseISO(dateEnded), addSeconds(parseISO(dateStarted), extraSeconds || 0))));
         // alert(`processedRecs=${processedRecs}\ndateEnded=${dateEnded}\ndateStarted=${dateStarted}\ncntPerHour=${cntPerHour}`);
         return cntPerHour;
      },

      async processedRecordsClicked(item) {
         this.loadingImports = true;
         this.isAnotherDelete = false;
         const filterDeletes = this.btHelpers.getInitialFilter();
         filterDeletes.standard[0].$match.status = 'processing';
         let result = await this.apiService.getDeletesCount(filterDeletes);
         this.loadingImports = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.currImport = item;
            this.dialogDelete = true;
            if (result.data)
               this.isAnotherDelete = true;
            else {
               this.currProcessedRecords = '';
               setTimeout(() => {
                  this.$refs.currProcessedRecords.focus();
               }, 10);
            }
         }
      },

      async deleteClicked() {
         this.isDeleteInProcess = true;
         const dateStarted = new Date().toISOString();
         const setIntervalDeletes = setInterval(() => {
            if (this.isDeleteInProcess) {
               // const DateEnded = new Date();
               // const h = differenceInHours(DateEnded, dateStarted);
               // const min = differenceInMinutes(DateEnded, dateStarted);
               // const m = min - h * 60;
               // const s = differenceInSeconds(DateEnded, dateStarted) - h * 3600 - m * 60;
               // this.deleteElapsedTime = '';
               // if (h) this.deleteElapsedTime = h + 'h ';
               // if (min) this.deleteElapsedTime += m + 'm ';
               // this.deleteElapsedTime += s + 's';
               this.deleteElapsedTime = this.calculateProcessingTime(new Date().toISOString(), dateStarted, 0);
            }
            else {
               clearInterval(setIntervalDeletes);
            }
         }, 1000);

         const filterOffers = this.btHelpers.getInitialFilter();
         filterOffers.standard[0].$match.importId = this.currImport.importId;
         let result = await this.apiService.deleteOffers(filterOffers, this.currProcessedRecords);
         this.isDeleteInProcess = false;
         if (result.logout)
            this.logout();
         else if (!result.message) {
            this.$emit('snackbar-event', `${result.data} records of '${this.currImport.name}' were deleted.`);
            this.closeDialogDelete();
            this.getImports();
         }
      },

      closeDialogDelete() {
         this.dialogDelete = false;
      },

      getMappings(columnMapping) {
         const mappings = [];
         if (columnMapping)
            for (let prop in columnMapping) {
               mappings.push(prop + '=' + columnMapping[prop]);
            }
         return mappings;
      },

      async newImportClicked() {
         this.dialogImport = true;
         this.formData = new FormData(this.imports[0] || {}, true);
         
         if (this.firstLoad) {
            this.tab = TABS.dedup;
            this.firstLoad = false;

            setTimeout(() => {
               this.tab = TABS.purl;
            }, 1);

            setTimeout(() => {
               this.tab = TABS.general;
            }, 5);
         }

         // setTimeout(() => {
         //    this.$refs.importName.focus();
         // }, 10);
      },

      purlsFormValidated(val) {
         this.log('in purlsFormValidated(): val=' + val);
         this.isPurlsFormValid = val;
      },
      dedupsValidated(val) {
         this.log('in dedupsValidated(): val=' + val);
         this.isDedupsFormValid = val;
      },
      emailsValidated(val) {
         this.log('in emailsValidated(): val=' + val);
         this.isEmailsFormValid = val;
      },

      generatePurlChanged(val) {
         if (!val) {
            this.isPurlsFormValid = true;
            this.formData.dedupHashMatching = false;
         }
      },

      hostChanged(val) {
         // alert('in hostChanged(): val=' + JSON.stringify(val) + ', formData.host=' + this.formData.host);
         this.actionsClass = 'mr-1';
         if (val)
            this.isSftpFormValid = this.$refs.sftpForm.validate();
         else {
            this.formData.passphrase = '';
            this.actionsClass += ' pr-5';
         }
      },

      hostCleared() {
         // alert('in hostCleared(): val=' + JSON.stringify(val) + ', formData.host=' + this.formData.host);
         this.hostChanged('');
      },

      passphraseChanged(val) {
         // alert('in passphraseChanged(): val=' + JSON.stringify(val) + ', formData.host=' + this.formData.host);
         if (val)
            this.isPgpFormValid = this.$refs.pgpForm.validate();
         // else
         //    this.formData.passphrase = '';
      },

      passphraseCleared() {
         // alert('in passphraseCleared(): val=' + JSON.stringify(val) + ', formData.host=' + this.formData.host);
         this.passphraseChanged('');
      },

      async generatePgpKeys() {
         // revocationCertificate also can be added to the end of const.
         const { privateKey, publicKey } = await generateKey({
            type: 'ecc', // Type of the key, defaults to ECC
            curve: 'p256', // ECC curve name, defaults to curve25519
            userIDs: [{ name: this.jwt.email.split('@')[0], email: this.jwt.email }], // you can pass multiple user IDs
            passphrase: this.formData.passphrase, // protects the private key
            format: 'armored' // output key format, defaults to 'armored' (other options: 'binary' or 'object')
         });

         this.formData.privateKey = privateKey;
         this.formData.publicKey = publicKey;

         this.copyToClipboardHint = 'Copy Public Key to the Clipboard';

         // console.log('privateKey:\n' + privateKey);     // '-----BEGIN PGP PRIVATE KEY BLOCK ... '
         // console.log('publicKey:\n' + publicKey);      // '-----BEGIN PGP PUBLIC KEY BLOCK ... '
         // console.log('revocationCertificate:\n' + revocationCertificate); // '-----BEGIN PGP PUBLIC KEY BLOCK ... '         
      },

      async copyToClipboard() {
         await navigator.clipboard.writeText(this.formData.publicKey);
         this.copyToClipboardHint = 'Public Key was copied to the clipboard at ' + new Date().toString().split(' ')[4];
      },

      cancelImport() {
         this.dialogImport = false;
         this.formData = new FormData({}, false);
         this.tab = TABS.general;
      },

      setImportName(arg) {
         if (hasOwn(arg, 'fileName') && !this.formData.importName.trim()) {
            //const fn = arg.fileName;
            //const ind = fn.lastIndexOf('.');
            //this.formData.importName = ind > 0 ? fn.substring(0, ind) : fn;
            this.formData.importName = arg.fileName.replace(/.csv/i,'').replace(/.txt/i,'').replace(/.zip/i,'');
         }
      },

      async saveImport(arg) {
         this.isMainFormValid = this.$refs.mainForm.validate();

         // alert('isPurlsFormValid=' + this.isPurlsFormValid +
         //       '\nisDedupsFormValid=' + this.isDedupsFormValid  +
         //       '\nisEmailsFormValid=' + this.isEmailsFormValid  +
         //       '\nisMainFormValid=' + this.isMainFormValid);

         if (this.isImportFormValid) {
            // alert('in saveImport(): formData.mappings=' + JSON.stringify(this.formData));
            this.loadingNewImport = true;

            const importData = {
               exportOnImportCompletion: this.formData.shouldExport,
               fileDelimiter: this.formData.delimiter,
               generatePURL: this.formData.shouldGeneratePurl,
               name: this.formData.importName,
               processBatchSize: this.formData.batchSize,
               purlGenerationScope: this.formData.scope,
               dedupHashMatching: this.formData.dedupHashMatching,
               typeCasting: this.formData.shouldCastTypes,
               persistEvent: this.formData.shouldPersistEvent,
               rejectOnError: this.formData.shouldRejectOnError,
               debug: this.formData.debug
            };

            if (this.formData.dedups.length > 0)
               importData.dedupColumns = this.formData.dedups;
            if (this.formData.latitudeColumn.trim())
               importData.latitudeColumn = this.formData.latitudeColumn;
            if (this.formData.longitudeColumn.trim())
               importData.longitudeColumn = this.formData.longitudeColumn;
            if (this.formData.emails.length > 0)
               importData.notificationEmails = this.formData.emails;
            if (this.formData.purls.length > 0)
               importData.purlColumns = this.formData.purls;
            if (hasOwn(arg, 'fileName'))
               importData.filePath = arg.fileName;
            if (this.formData.host) {
               importData.sftp = {
                  sftpCredentials: {
                     host: this.formData.host,
                     username: this.formData.sftpUsername,
                     password: this.formData.sftpPass
                  }
               };
               if (this.formData.port)
                  importData.sftp.sftpCredentials.port = this.formData.port;
               if (this.formData.sftpPath)
                  importData.sftp.sftpPath = this.formData.sftpPath;
               if (this.formData.passphrase) {
                  importData.sftp.pgpCredentials = {
                     passPhrase: this.formData.passphrase,
                     privateKey: this.formData.privateKey
                  };
               }
            }

            this.log('in saveImport(): importData=' + JSON.stringify(importData));
            const result = await this.apiService.createImport(importData);
            this.loadingNewImport = false;
            if (result.logout)
               this.logout();
            else if (!result.message) {
               this.$emit('snackbar-event', `'${importData.name}' was saved.`);
               // this.importsCount = await this.getImportsCount();
               this.importsCount++;
               this.getImports();
               this.cancelImport();
               this.hasImport = true;
            }
         }
      },

      async filterChanged2(filter) {
         // alert('in filterChanged2(): filter=' + JSON.stringify(filter) + '\noptions=' + JSON.stringify(this.options));
         this.filter2 = filter;
         this.importsCount = await this.getImportsCount();
         this.nextAction();
      },

      async nextAction() {
         const currOptions = JSON.stringify(this.options);
         const newOptions = JSON.parse(currOptions);
         newOptions.page = 1;
         if (JSON.stringify(newOptions) === currOptions)
            await this.getImports();
         else
            this.options = newOptions;
      }
   },

   created() {
      this.isClipboard = navigator.clipboard;
      this.init();
   },
}
</script>

<style scoped>
.expanded-header {
   font-style: italic;
   font-weight: bold;
}
.v-expansion-panel-header, .v-expansion-panel-header--active {
   padding: 0 16px;
   min-height: 40px !important;
}
</style>
