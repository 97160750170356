<template>

    <div class="file">

        <v-container>
            <v-layout row align-center>
                <upload-btn ref="uploadButton" @file-update="update" title="File to Upload" accept=".csv,.zip,.gz,.txt" v-if="!noUpload" >
                    <template slot="icon-left">
                        <v-icon left>add</v-icon>
                    </template>
                </upload-btn>
                <v-btn color="primary" @click="onSubmit" :disabled="!canSubmit">Submit</v-btn>
                <!-- <v-btn color="success" @click="onSubmit" :disabled="true">Submit</v-btn> -->
                
            </v-layout>
            <v-layout row align-right>
                <v-progress-circular v-if="inProgress"
                    
                    color="primary"
                    :value="progress"
                >{{ progress }}</v-progress-circular>
                <h5>{{ message }}</h5>
            </v-layout>
        </v-container>

    </div>
    
</template>

<script>
import axios from 'axios';
import UploadButton from 'vuetify-upload-button';

export default {
    name: 'ImportFileUpload',
    components: {
        'upload-btn': UploadButton
    },
    props: {
        notActive: Boolean,
        fileName: String,
        noUpload: Boolean
    },
    data: function() {
        return {
            file: '',
            message: '',
            inProgress: false,
            progress: 0
        }
    },
    methods: {
        update: function(file){
            //alert(this.notActive);
            this.file = file;
            if(file){
                this.message = "";
                this.$emit("file-selected", {fileName: file.name, fileSize: file.size, fileType: file.type});
            }
        },
        onSubmit: async function() {
            if(this.noUpload){
                this.$emit("uploaded", {fileName: 'overrided-by-sftp-file'});
                return;
            }

            const formdata = new FormData();
            formdata.append('file', this.file);

            try{
                
                const result = await axios({url: process.env.VUE_APP_EDGE_SERVICE_ENDPOINT + '/api/contact-service/v1/uploads/' + ((this.fileName) ? this.fileName : this.file.name) + "?type=" + this.file.type + ((this.folder) ? "&folder=" + this.folder : "") + ((this.isPublic) ? "&isPublic=" + this.isPublic : ""), method: 'GET' })
                
                this.inProgress = true;
                
                let xhr = new XMLHttpRequest();
                let self = this;
                xhr.upload.addEventListener("progress", function(evt){
                    if (evt.lengthComputable) {
                        //console.log("add upload event-listener " + evt.loaded + "/" + evt.total);
                        self.progress = ((evt.loaded / evt.total) * 100).toFixed(0);
                    }
                }, false);

                xhr.onloadstart = function (e) {
                    console.log("start")
                }
                xhr.onloadend = function (e) {
                    self.$emit("uploaded", {fileName: self.file.name, fileSize: self.file.size, fileType: self.file.type});
                    self.inProgress = false;
                    let elapsed = new Date().getTime() - start;
                    //self.message = "Uploaded " + (self.file.size/1024).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " KB in " + elapsed/1000 + " seconds.";
                    try{
                        self.$refs.uploadButton.clear();
                    }
                    catch(err){

                    }
                    self.progress =0;
                    console.log("end")
                }
                let start = new Date().getTime();
                xhr.open('PUT', result.data.url, true);
                xhr.send(this.file);
            }
            catch(err){
                console.log(err);
                this.message = "Something went wrong!"
            }

        }
    },
    computed: {
        canSubmit(){
            //inProgress || !file || notActive
            if(this.noUpload){
                if(!this.notActive)
                    return true;
                else
                    return false;
            } else {
                if(this.inProgress || !this.file || this.notActive)
                    return false;
                else
                    return true
            }
        }
    },
    created() {      
      this.message = '';
      //alert(this.noUpload);
    }
    
}
</script>

