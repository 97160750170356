<template>
  <v-app class="grey lighten-4">
    <Navbar   /> 

    <v-content>
      <!-- <transition name="fade"> -->
        <!-- <keep-alive> -->
          <router-view></router-view>
        <!-- </keep-alive> -->
      <!-- </transition> -->
    </v-content>
    
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'

export default {
  components: { Navbar },
  name: 'App',
  data () {
    return {
      userActivityTimeout: null
    }
  },
  methods: {
    resetUserActivityTimeout() {
      console.log(`resetUserActivityTimeout at ${new Date()}`);
      const preferences = this.$store.getters.user.preferences;
      //console.log('preferences:', preferences);
      let timeout = 30;
      if(preferences && preferences.timeout){
        timeout = preferences.timeout;
      }
      
      clearTimeout(this.userActivityTimeout);
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction();
      }, timeout * 60 * 1000);
    },
    inactiveUserAction() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      })
    },
    activateActivityTracker() {
      window.addEventListener("click", this.resetUserActivityTimeout);
    }
  },
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeDestroy() {
    window.removeEventListener("click", this.resetUserActivityTimeout);
  
    clearTimeout(this.userActivityTimeout);
  },
  computed: {
    hasNavbar: function(){
      if(this.$route.query.token ){
        return false;
      }
      else{
        return true;
      }
    }
  },
  created(){
    
  },
  mounted: function () {

    const vm = this;

    // Add a 401 response interceptor
    this.$http.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === error.response.status) {
          return new Promise(function (resolve, reject) {
            vm.$store.dispatch('logout').then(() => {
              try {
                //console.log('vm.$router.currentRoute: ', vm.$router.currentRoute)
                if(vm.$router.currentRoute.path != '/'){
                  vm.$router.push('/');
                  if(gapi)
                    gapi.auth2.getAuthInstance().signOut();
                }
              } catch (error) {
              }
            });

            reject(error);
          })
        } else {
            return Promise.reject(error);
        }
    });

  }
}
</script>

<style>
  .theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
    background-color: rgba(0, 0, 0, 0);
  }
</style>

